// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import _isString from 'lodash/isString'

// Components
import BButton from 'ui/blocks/Button'
import StatefulButton from './StatefulButton'
import Icon from 'ui/components/Icon'

// Shared
import promisePropType from 'shared/prop_types/promise'
import { Routing as RoutingUtils } from 'client/v2/utils/utils'

/**
 * Button for displaying loading, success, or error state.
 * Hooks into a Promise resolve or reject to display the state accordingly.
 *
 * @example
 *   <Button icon="save">Save</Button>
 */
export default class Button extends Component {
  static displayName = 'Button'

  static propTypes = {
    id: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    color: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    iconFloat: PropTypes.oneOf(['left', 'right']),
    size: PropTypes.string,
    borderRadius: PropTypes.string,
    href: PropTypes.string,
    reset: PropTypes.number,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    outline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    noBorder: PropTypes.bool,
    promise: promisePropType,
    target: PropTypes.string,
    textAlign: PropTypes.string,
    'data-tid': PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    round: PropTypes.bool,
    uppercase: PropTypes.bool,
    noBlankIcon: PropTypes.bool,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    inlineBlock: PropTypes.bool,
    withBranding: PropTypes.bool,
    loading: PropTypes.bool
  }

  static defaultProps = {
    color: 'primary',
    size: 'md',
    type: 'button',
    reset: 2500
  }

  constructor (props) {
    super(props)

    this.state = {
      state: null
    }
  }

  componentDidUpdate = (prevProps) => {
    this._isMounted = true

    const { promise } = this.props

    if (promise && promise !== prevProps.promise) {
      // There's a promise to hook into
      this.handlePromise(promise)
    }

    if (prevProps.loading !== this.props.loading && this._isMounted) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        state: this.props.loading ? 'loading' : null
      })
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false

    if (this.resetTimer) {
      clearTimeout(this.resetTimer)
    }
  }

  get icon () {
    const data = this.props.icon

    if (!data) return

    if (_isString(data)) {
      return { icon: data }
    } else {
      return data
    }
  }

  /**
   * Handler: There's a promise to hook into.
   */
  handlePromise = (promise) => {
    // Set state to loading immediately
    this.setState({ state: 'loading' })

    promise
      .then(() => {
        // Promise is resolved, set state to success
        if (this._isMounted) {
          this.setState({ state: 'success' })
        }
      })
      .catch(() => {
        // Promise is rejected, set state to error
        if (this._isMounted) {
          this.setState({ state: 'error' })
        }
      })
      // This will always be executed, for catch as well
      .then(() => {
        // Reset state only if `reset` prop is not falsy
        if (this.props.reset) {
          // Reset a previous reset timer, else this may cause unexpected behavior
          clearTimeout(this.resetTimer)

          if (this._isMounted) {
            // Promise has either been resolved or reject, reset state after x seconds
            this.resetTimer = setTimeout(() => this.setState({ state: null }), this.props.reset)
          }
        }
      })
  }

  /**
   * Renders one of the states of the button; loading, success, or error.
   */
  renderButtonState = (state) => {
    return <StatefulButton state={state} {...this.props} />
  }

  /**
   * Render the button itself.
   */
  renderButton = () => {
    const {
      id,
      children,
      color,
      size,
      borderRadius,
      disabled,
      block,
      outline,
      noBorder,
      href,
      target,
      iconFloat,
      textAlign,
      inlineBlock,
      'data-tid': tid,
      round,
      uppercase,
      noBlankIcon,
      modifiers,
      withBranding,
      ...otherProps
    } = this.props
    const onClick = disabled ? undefined : this.props.onClick
    const ButtonComponent = href ? BButton.Link : BButton.Button
    const componentProps = {}
    const noLabel = !children

    if (href) {
      componentProps.href = href

      if (href !== '#') {
        componentProps.onClick = RoutingUtils.navigateLink
      }
    }

    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    return (
      <BButton
        color={color}
        size={size}
        block={block}
        inlineBlock={inlineBlock}
        disabled={disabled}
        outline={outline}
        noBorder={noBorder}
        noLabel={noLabel}
        iconFloat={iconFloat}
        textAlign={textAlign}
        uppercase={uppercase}
        data-tid={tid}
        round={round}
        modifiers={nestedModifiers.self}
        id={id}
        borderRadius={borderRadius}
        withBranding={withBranding}
      >
        <ButtonComponent
          modifiers={nestedModifiers.buttonComponent}
          {...componentProps}
          {...otherProps}
          disabled={disabled}
          onClick={onClick}
          target={target}
        >
          {this.icon && (!iconFloat || iconFloat === 'left') &&
            <Icon {...this.icon} modifiers={nestedModifiers.icon} />
          }
          {children && <BButton.Label>{children}</BButton.Label>}
          {target === '_blank' && !noBlankIcon && <Icon icon="external-link-alt" />}
          {this.icon && (iconFloat === 'right') && <Icon {...this.icon} modifiers={nestedModifiers.icon} />}
        </ButtonComponent>
      </BButton>
    )
  }

  render () {
    if (this.state.state) {
      return this.renderButtonState(this.state.state)
    } else {
      return this.renderButton()
    }
  }
}
