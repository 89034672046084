import request from 'axios'
import merge from 'lodash/merge'

// Track events
// @example
//   EventTracker.track('Signedup', { source: 'Organic' })
//
class EventTracker {
  static track (name, data) {
    const properties = merge(Object.assign({}, window.globalTrackingData || {}), data)

    // For proper breakdowns in Mixpanel we always want feature to be set, if feature_category is present
    if (properties.feature_category && !properties.feature) {
      properties.feature = properties.feature_category
    }

    if (window.analytics && window.analytics.track) {
      window.analytics.track(name, properties)
    }

    if (window.environment === 'development' || window.environment === 'test') {
      console.log(`%c EventTracker track: ${name}, ${JSON.stringify(properties)}`, 'color: #24ad4e')
    }

    const sendData = {
      event: name,
      properties
    }

    return request({
      method: 'POST',
      url: '/track',
      data: sendData
    })
  }

  static featureCategory () {
    const path = location.pathname

    // On a hard reload, the url is different then when the user navigates to the url
    if (path.includes('/product_groups') || path.includes('/products')) {
      return 'Products'
    } else if (path.includes('/customers')) {
      return 'Customers'
    }
  }

  static trackDispatch (action, state) {
    switch (action.type) {
      case 'IMPORT_INITIALIZE':
        return EventTracker.track('Import visit', {
          type: action.payload.type,
          feature_category: this.featureCategory(),
          feature: 'Import'
        })
      case 'IMPORT_CANCEL':
        if (state && state.import.uploaded) {
          return EventTracker.track('Import cancel', {
            feature_category: this.featureCategory(),
            feature: 'Import'
          })
        }
        break
      case 'IMPORT_UPLOAD_FILE':
        return EventTracker.track('Import upload', {
          file: action.payload.file.name,
          columns: action.payload.columns.length,
          items: action.payload.data.length,
          feature_category: this.featureCategory(),
          feature: 'Import'
        })
      case 'IMPORT_FINISH_MAPPING':
        return EventTracker.track('Import finish mapping', {
          mapped_columns: state.import.mapping.size,
          feature_category: this.featureCategory(),
          feature: 'Import'
        })
      case 'IMPORT_START':
        return EventTracker.track('Import start', {
          feature_category: this.featureCategory(),
          feature: 'Import'
        })
      case 'IMPORT_UPDATE':
        if (action.payload.import.completed && (state.import.step !== 'completed')) {
          return EventTracker.track('Import complete', {
            feature_category: this.featureCategory(),
            feature: 'Import'
          })
        }
        break
      case 'PAYMENTS_CREATE_START': {
        const {
          attributes
        } = action.payload.options

        const data = {
          context: attributes.document_id ? 'invoice' : 'order',
          amount: attributes.amount_in_cents,
          deposit: attributes.deposit_in_cents
        }

        if (attributes.payment_type.startsWith('manual')) {
          data.type = attributes.payment_type
          data.method = attributes.transaction_type
          data.custom_date = (attributes.paid_at != null)

          return data.custom_date
        } else {
          data.type = `${attributes.payment_type}_${attributes.transaction_type}`

          return data.type
        }
      }
    }
  }
}

export default EventTracker
