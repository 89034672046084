import { color, size, fontSize, fontWeight, unit } from 'ui/themes/utils'

export default (theme) => {
  const positions = ['top', 'right', 'bottom', 'left']
  const borderPositions = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
  const spacings = ['padding', 'margin']
  const dimensions = ['width', 'height']
  const screenSizes = theme.size.screenSizes
  const displayProperties = [
    'none',
    'inherit',
    'block',
    'contents',
    'flex',
    'grid',
    'inline',
    'inline-block',
    'revert',
    'table',
    'unset'
  ]

  const modifiers = {
    'text-shadow': () => `
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    `,
    'text-line-through': () => `
      text-decoration: line-through;
    `,
    'text-center': () => `
      text-align: center;
    `,
    'text-right': () => `
      text-align: right;
    `,
    'text-left': () => `
      text-align: left;
    `,
    'text-lowercase': () => `
      text-transform: lowercase;
    `,
    'text-uppercase': () => `
      text-transform: uppercase;
    `,
    'float-left': () => `
      float: left;
    `,
    'float-right': () => `
      float: right;
    `,
    'position-relative': () => `
      position: relative;
    `,
    'position-absolute': () => `
      position: absolute !important;
    `,
    'fixed-background': (props) => `
      background-color: ${props.background};
    `,
    'no-border': () => `
      border: none !important;
    `,
    'no-overflow': () => `
      overflow: hidden !important;
    `,
    'preserve-newlines': () => `
      white-space: pre-wrap;
    `,
    'break-words': () => `
      word-break: break-all;
    `,
    'flex-row': () => `
      flex-direction: row;
    `,
    'flex-column': () => `
      flex-direction: column;
    `,
    'flex-wrap': () => `
      flex-wrap: wrap;
    `,
    'flex-nowrap': () => `
      flex-wrap: nowrap;
    `,
    'flex-grow': () => `
      flex-grow: 1;
    `,
    'justify-start': () => `
      justify-content: flex-start;
    `,
    'justify-end': () => `
      justify-content: flex-end;
    `,
    'justify-center': () => `
      justify-content: center;
    `,
    'justify-between': () => `
      justify-content: space-between;
    `,
    'justify-around': () => `
      justify-content: space-around;
    `,
    'align-start': () => `
      align-items: flex-start;
    `,
    'align-end': () => `
      align-items: flex-end;
    `,
    'align-center': () => `
      align-items: center;
    `,
    'v-align-middle': () => `
      vertical-align: middle;
    `,
    'rounded-circle': () => `
      border-radius: 50%;
    `,
    'text-break': () => `
      word-break: break-word !important;
      overflow-wrap: break-word !important;
    `,
    'text-nowrap': () => `
      white-space: nowrap;
    `,
    'margin-x-auto': () => `
      margin-left: auto;
      margin-right: auto;
    `,
    'overflow-hidden': () => `
      overflow: hidden;
    `,
    'select-none': () => `
      user-select: none;
    `,
    'w-full': () => `
      width: 100%;
    `
  }

  Object.keys(screenSizes).forEach((screenSize) => {
    // Responsive right text align properties
    // Example text-right-md
    modifiers[`text-right-${screenSize}`] = (props) => `
        @media (min-width: ${screenSizes[screenSize]}) {
          text-align: right !important;
        }
      `
    // Responsive left text align properties
    // Example text-left-lg
    modifiers[`text-left-${screenSize}`] = (props) => `
        @media (min-width: ${screenSizes[screenSize]}) {
          text-align: left !important;
        }
      `
    // Responsive fixed position
    // Example position-fixed-lg
    modifiers[`position-fixed-${screenSize}`] = (props) => `
        @media (min-width: ${screenSizes[screenSize]}) {
          position: fixed !important;
        }
      `
  })

  // Limits a text to be a given amount of lines and adds ellipsis if necessary
  // Example: line-clamp-2 limits a text to 2 lines
  Array(3).fill().forEach((_, index) => {
    modifiers[`line-clamp-${index + 1}`] = (props) => `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${index + 1};
      -webkit-box-orient: vertical;
    `
  })

  // Width/height properties
  dimensions.forEach((dimension) => {
    // A width/height of 100%
    // Example: width-100p
    modifiers[`${dimension}-100p`] = (props) => `
      ${dimension}: 100% !important;
    `
    // A maximum width/height of X %
    // Example: max-width-100p
    // Supports 1 till 100
    Array(100).fill().forEach((_, index) => {
      modifiers[`max-${dimension}-${index + 1}p`] = (props) => `
        max-${dimension}: ${index + 1}% !important;
      `
    })
    // Auto width/height
    // Example: width-auto
    modifiers[`${dimension}-auto`] = (props) => `
      ${dimension}: auto !important;
    `
    // vh/vw width/height
    // Example: height-100vh, width-100vw
    modifiers[`${dimension}-100v${dimension[0]}`] = (props) => `
      ${dimension}: 100v${dimension[0]} !important;
    `
  })

  // Control the width/height of an element, supports 25 till 1500, in steps of 25
  // Example: width-150
  // Example: max-width-650
  // Example: min-height-50
  Array(60).fill().forEach((_, index) => {
    const size = (index + 1) * 25

    modifiers[`width-${size}`] = (props) => `
      width: ${size}px;
    `
    modifiers[`max-width-${size}`] = (props) => `
      max-width: ${size}px;
    `
    modifiers[`height-${size}`] = (props) => `
      height: ${size}px !important;
    `
    modifiers[`min-height-${size}`] = (props) => `
      min-height: ${size}px;
    `
    modifiers[`min-width-${size}`] = (props) => `
      min-width: ${size}px;
    `

    // Responsive width/height properties
    // width-{breakpoint}-{size}
    // Example width-md-250
    Object.keys(screenSizes).forEach((screenSize) => {
      modifiers[`width-${screenSize}-${size}`] =
        (props) => `
          @media (min-width: ${screenSizes[screenSize]}) {
            width: ${size}px;
          }
        `
    })
  })

  // Font weight, example: font-weight-lighter
  Object.keys(theme.font.weight).forEach((weight) => {
    modifiers[`font-weight-${weight}`] = (props) => `font-weight: ${fontWeight(weight)(props)} !important;`
  })

  // Font size, example: font-size-lg
  Object.keys(theme.font.size).forEach((size) => {
    modifiers[`font-size-${size}`] = (props) => `font-size: ${fontSize(size)(props)} !important;`
  })

  // Font size, example: line-height-lg
  Object.keys(theme.font.size).forEach((size) => {
    modifiers[`line-height-${size}`] = (props) => `line-height: ${fontSize(size)(props)} !important;`
  })

  // Box shadows, example: box-shadow-lg
  Object.keys(theme.size.shadow).forEach((shadowSize) => {
    modifiers[`box-shadow-${shadowSize}`] = (props) => `box-shadow: ${size('shadow', shadowSize)(props)} !important;`
  })

  // Border radius, example: border-radius-lg
  Object.keys(theme.size.borderRadius).forEach((radiusSize) => {
    modifiers[`border-radius-${radiusSize}`] =
      (props) => `border-radius: ${size('borderRadius', radiusSize)(props)} !important;`
  })

  // Specific position border radius, example: border-top-left-radius-lg
  borderPositions.forEach((position) => {
    Object.keys(theme.size.borderRadius).forEach((radiusSize) => {
      modifiers[`border-${position}-radius-${radiusSize}`] =
        (props) => `border-${position}-radius: ${size('borderRadius', radiusSize)(props)} !important;`
    })
  })

  // Disable border radius, example: no-border-radius-bottom-left
  borderPositions.forEach((position) => {
    modifiers[`no-border-radius-${position}`] = (props) => `border-${position}-radius: 0 !important;`
  })

  positions.forEach((position) => {
    // Borders on specific sides, example: border-bottom
    modifiers[`border-${position}`] = (props) => {
      return `border-${position}: 1px solid ${color('Misc/Divider')(props)} !important;`
    }
    // Disable borders, example: no-border-bottom
    modifiers[`no-border-${position}`] = (props) => `border-${position}: none !important;`
  })

  Object.keys(theme.color).forEach((themeColor) => {
    // Text color, example: text-white
    modifiers[`text-${themeColor}`] = (props) => `color: ${color(themeColor)(props)} !important;`
    // Border with color, example: border-Primary/Base
    modifiers[`border-${themeColor}`] = (props) => `border: 1px solid ${color(themeColor)(props)} !important;`
    // Border color, example: border-color-Primary/Base
    modifiers[`border-color-${themeColor}`] = (props) => `border-color: ${color(themeColor)(props)} !important;`
    // Background color, example: background-color-Primary/Base
    modifiers[`background-color-${themeColor}`] = (props) => `background-color: ${color(themeColor)(props)} !important;`
  })

  // Example: gap-xl
  // Defined as: <Div modifiers={'gap-xl'} />
  Object.keys(theme.size.padding).forEach((paddingSize) => {
    modifiers[`gap-${paddingSize}`] =
      (props) => `gap: ${size('padding', paddingSize)(props)} !important;`
  })

  // Example: gap-2
  // Defined as: <Div modifiers={'gap-2'} />
  Object.keys(theme.units).forEach((unitSize) => {
    modifiers[`gap-${unitSize}`] = (props) => `gap: ${unit(unitSize)(props)} !important;`
  })

  spacings.forEach((spacingName) => {
    // Example (padding): padding: 10px;
    // Defined as: <Div modifiers={'padding'} size="xl" />
    modifiers[spacingName] = (props) => `${spacingName}: ${size(spacingName)(props)};`
    // Example: padding-xl
    // Defined as: <Div modifiers={'padding-xl'} />
    Object.keys(theme.size[spacingName]).forEach((paddingSize) => {
      modifiers[`${spacingName}-${paddingSize}`] =
        (props) => `${spacingName}: ${size(spacingName, paddingSize)(props)} !important;`
    })

    // Example: padding-2
    // Defined as: <Div modifiers={'padding-2'} />
    Object.keys(theme.units).forEach((unitSize) => {
      modifiers[`${spacingName}-${unitSize}`] = (props) => `${spacingName}: ${unit(unitSize)(props)} !important;`
    })

    // Example (no-padding): padding: 0;
    modifiers[`no-${spacingName}`] = (props) => `${spacingName}: 0 !important;`

    if (spacingName === 'margin') {
      // Auto margins
      // Example (margin-top-auto): margin-top: auto;
      positions.forEach((position) => {
        modifiers[`${spacingName}-${position}-auto`] = (props) => `margin-${position}: auto !important;`
      })
    }

    positions.forEach((position) => {
      Object.keys(theme.size[spacingName]).forEach((paddingSize) => {
        // Example (margin-top-sm): margin-top: 10px;
        modifiers[`${spacingName}-${position}-${paddingSize}`] =
          (props) => `${spacingName}-${position}: ${size(spacingName, paddingSize)(props)} !important;`

        // Negative margin
        // Example (margin-top-n-sm): margin-top: -10px;
        modifiers[`${spacingName}-${position}-n-${paddingSize}`] =
          (props) => `${spacingName}-${position}: -${size(spacingName, paddingSize)(props)} !important;`

        // Responsive margins
        // {property}-{sides}-{breakpoint}-{size}
        // Example (margin-top-md-sm): margin-top: 10px;
        Object.keys(screenSizes).forEach((screenSize) => {
          modifiers[`${spacingName}-${position}-${screenSize}-${paddingSize}`] =
            (props) => `
              @media (min-width: ${screenSizes[screenSize]}) {
                ${spacingName}-${position}: ${size(spacingName, paddingSize)(props)} !important;
              }
            `
        })
      })

      // Example (no-margin-bottom): margin: 0;
      modifiers[`no-${spacingName}-${position}`] = (props) => `${spacingName}-${position}: 0 !important;`

      // Responsive disabling margins
      // Example no-margin-top-md
      Object.keys(screenSizes).forEach((screenSize) => {
        modifiers[`no-${spacingName}-${position}-${screenSize}`] =
          (props) => `
            @media (min-width: ${screenSizes[screenSize]}) {
              ${spacingName}-${position}: 0 !important;
            }
          `
      })
    })
  })

  displayProperties.forEach((displayProp) => {
    modifiers[`display-${displayProp}`] = (props) => `display: ${displayProp} !important;`

    // Responsive display properties
    // display-{breakpoint}-{displayProp}
    // Example display-md-block
    Object.keys(screenSizes).forEach((screenSize) => {
      modifiers[`display-${screenSize}-${displayProp}`] =
        (props) => `
          @media (min-width: ${screenSizes[screenSize]}) {
            display: ${displayProp} !important;
          }
        `
    })
  })

  return modifiers
}
